import React, { useState } from 'react';
import '../css/Home.css';
import { useNavigate } from 'react-router-dom';
import tuvacolLogo from '../assets/logo_tuvacol.png';
import googleIcon from '../assets/logo_google.svg';
import image1 from '../assets/image1.png'; 
import image3 from '../assets/image3.png'; 
import image4 from '../assets/image4.png';
import image2 from '../assets/image2.png';
import { auth } from './FireBase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import AccessModal from '../Components/AccessModal'; // Import your modal component

export default function Home() {
  const navigate = useNavigate(); 
  const provider = new GoogleAuthProvider();
  const [isModalVisible, setModalVisible] = useState(false); // State to manage modal visibility

  function signIn() {
    signInWithPopup(auth, provider)
      .then((result) => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const email = user.email;
            const dominio = "tuvacol.com";
            if (!email.endsWith(`@${dominio}`)) {
              signOut(auth).then(() => {
                console.log("Usuario desconectado por dominio no permitido");
                setModalVisible(true); // Show the denied access modal
              });
            } else {
              navigate('/dashboard');
            }
          }
        });
      })
      .catch((error) => {
        console.error("Error en el inicio de sesión: ", error);
      });
  }

  const handleCloseModal = () => {
    setModalVisible(false); // Close the modal
  };

  return (
    <div className="container">
      <AccessModal isVisible={isModalVisible} onClose={handleCloseModal} />
      <div className="logo-container">
        <img src={tuvacolLogo} alt="Tuvacol Logo" className="tuvacol-logo" />
      </div>
      <div className="left-section">
        <h1 className='title-home'>Tuvacol Optimize</h1>
        <div className="circle-grid">
          <div className="circle">
            <img src={image1} alt="Logo1" className="circle-image" />
          </div>

          <div className="circle">
            <img src={image2} alt="Logo2" className="circle-image1" />
          </div>

          <div className="circle">
            <img src={image3} alt="Logo3" className="circle-image1" />
          </div>

          <div className="circle">
            <img src={image4} alt="Logo4" className="circle-image" />
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="login-box">
          <h2 className='title-login'>Bienvenido</h2>
          <p>Inicia sesión con tu cuenta para continuar</p>
          <button className="login-btn google-btn" onClick={signIn}>
            <img src={googleIcon} alt="Google Icon" className="google-icon" />
            Sign in with Google
          </button>
        </div>
      </div>
    </div> 
  );
}
