import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './FireBase';
import { onAuthStateChanged } from 'firebase/auth';
import '../css/Dashboard.css';
import Form from '../pages/Form/Form.js';
import BoardP from '../pages/Board/Boardp.js';
import Board from './Board/Board.js';
import Formp from './Form/FormP.js';
import FormT from './Form/FormT.js';  
import BoardT from './Board/Boardt.js';  
import Header from '../Components/Header.jsx';
import AdminN from './Admin/AdminN.js';  
import AdminP from './Admin/AdminP.js';  
import AdminT from './Admin/AdminT.js';  
import { BiHome, BiFile, BiCog } from 'react-icons/bi';

export default function Dashboard() {
  const [activePage, setActivePage] = useState('board');
  const [Panelpintura, setPanelPintura] = useState(false);
  const [Formniple, setFormniple] = useState(false);
  const [AdminNiple, setAdminNiple] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUserEmail(user.email); // Guardar el correo del usuario autenticado
      } else {
        setIsAuthenticated(false);
        navigate('/'); // Redirige a la página de inicio de sesión si no está autenticado
      }
    });

    return () => unsubscribe(); // Limpiar el suscriptor al desmontar
  }, [navigate]);

  if (!isAuthenticated) {
    return null; // Puedes mostrar un cargador o algo mientras se verifica la autenticación
  }

  // Determina si el usuario tiene acceso completo
  const hasFullAccess = userEmail === 'gestorabastecimiento@tuvacol.com' || userEmail === 'jefebodbq@tuvacol.com'|| userEmail === 'auxlogisticabq@tuvacol.com'|| userEmail === 'controlinvenbq@tuvacol.com' || userEmail === 'asisdespachosbq@tuvacol.com';

  return (
    <div className="dashboard-container">
      <Header title="Tablero de Gestión Operativa" />
      <div className="dashboard-main">
        <nav className="dashboard-sidebar">
          <ul>
            {/* Módulo Centro de Control de Operaciones */}
            <li>
              <button
                className={`dashboard-menu-button ${Panelpintura ? 'active' : ''}`}
                onClick={() => {
                  setPanelPintura(!Panelpintura);
                  setFormniple(false);
                  setAdminNiple(false);
                }}
              >
                <BiHome className="icon" /> Centro de Control de Operaciones
              </button>

              {Panelpintura && (
                <div className="panel-options">
                  <button
                    className={`dashboard-submenu-button ${activePage === 'board' ? 'active' : ''}`}
                    onClick={() => setActivePage('board')}
                  >
                    Gestión De Elaboración De Niples
                  </button>
                  <button
                    className={`dashboard-submenu-button ${activePage === 'boardp' ? 'active' : ''}`}
                    onClick={() => setActivePage('boardp')} 
                  >
                    Gestión De Elaboración De Horno De Pintura
                  </button>
                  <button
                    className={`dashboard-submenu-button ${activePage === 'boardt' ? 'active' : ''}`} 
                    onClick={() => setActivePage('boardt')} disabled={!hasFullAccess} 
                  >
                    Gestión De Elaboración De Torno En Taller
                  </button>
                </div>
              )}
            </li>

            {/* Módulo Solicitud */}
            <li>
              <button
                className={`dashboard-menu-button ${Formniple ? 'active' : ''}`}
                onClick={() => {
                  setFormniple(!Formniple);
                  setPanelPintura(false);
                  setAdminNiple(false);
                }}
              >
                <BiFile className="icon" /> Solicitud
              </button>

              {Formniple && (
                <div className="form-options">
                  <button
                    className={`dashboard-submenu-button ${activePage === 'form' ? 'active' : ''}`}
                    onClick={() => setActivePage('form')}
                  >
                    Solicitud de Elaboración De Niples
                  </button>
                  <button
                    className={`dashboard-submenu-button ${activePage === 'formp' ? 'active' : ''}`}
                    onClick={() => setActivePage('formp')} 
                  >
                    Solicitud de Elaboración De Horno De Pintura
                  </button>
                  <button
                    className={`dashboard-submenu-button ${activePage === 'formt' ? 'active' : ''}`}
                    onClick={() => setActivePage('formt')} disabled={!hasFullAccess}
                  >
                    Solicitud de Torno
                  </button>
                </div>
              )}
            </li>

            {/* Módulo Centro de Control de Producción */}
            {hasFullAccess && (
              <li>
                <button
                  className={`dashboard-menu-button ${AdminNiple ? 'active' : ''}`}
                  onClick={() => {
                    setAdminNiple(!AdminNiple);
                    setPanelPintura(false);
                    setFormniple(false);
                  }}
                >
                  <BiCog className="icon" /> Centro de Control de Producción
                </button>

                {AdminNiple && (
                  <div className="admin-options">
                    <button
                      className={`dashboard-submenu-button ${activePage === 'adminn' ? 'active' : ''}`}
                      onClick={() => setActivePage('adminn')}
                    >
                      Niples
                    </button>
                    <button
                      className={`dashboard-submenu-button ${activePage === 'adminp' ? 'active' : ''}`}
                      onClick={() => setActivePage('adminp')}                     >
                       Horno De Pintura
                    </button>
                    <button
                      className={`dashboard-submenu-button ${activePage === 'admint' ? 'active' : ''}`}
                      onClick={() => setActivePage('admint')} 
                    >
                      Torno
                    </button>
                  </div>
                )}
              </li>
            )}
          </ul>
        </nav>

        <div className="dashboard-content">
          {activePage === 'form' && <Form />}
          {activePage === 'board' && <Board />}
          {activePage === 'boardp' && <BoardP />}
          {activePage === 'formp' && <Formp />}
          {activePage === 'boardt' && <BoardT />}  
          {activePage === 'formt' && <FormT />} 
          {activePage === 'adminn' && <AdminN />}  
          {activePage === 'adminp' && <AdminP />}  
          {activePage === 'admint' && <AdminT />}  
        </div>
      </div>
    </div>
  );
}
