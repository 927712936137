import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { db } from '../FireBase';
import '../../css/Admin.css';
import { IoMdInformationCircle } from "react-icons/io";
import { IoMdInformationCircleOutline } from "react-icons/io";


export default function Administrativo() {
  const [requests, setRequests] = useState([]);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeRequestId, setActiveRequestId] = useState(null);
  const [activeHistoryId, setActiveHistoryId] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState('');
  const [filterPerson, setFilterPerson] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "RequestPaint"), (querySnapshot) => {
      const requestData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const filteredRequests = requestData.filter(request =>
        request.estado !== 'aprobado' && request.estado !== 'rechazado' && request.estado !== 'completado'
      );
      const completedRequests = requestData.filter(request =>
        request.estado === 'aprobado' || request.estado === 'rechazado' || request.estado === 'completado'
      );

      setRequests(filteredRequests);
      setHistory(completedRequests);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleRequestDetails = (id) => {
    setActiveRequestId((prevActiveId) => (prevActiveId === id ? null : id));
  };

  const toggleHistoryDetails = (id) => {
    setActiveHistoryId((prevActiveId) => (prevActiveId === id ? null : id));
  };

  const handleApproval = async (id) => {
    const updatedRequest = {
      ...requests.find(request => request.id === id),
      estado: 'aprobado',
      personaAsignada: selectedPerson,
      fechaProgramada: scheduledDate
    };

    const requestRef = doc(db, "RequestPaint", id);
    await updateDoc(requestRef, updatedRequest);

    setSelectedPerson('');
    setScheduledDate('');
  };

  const handleRejection = async (id) => {
    const updatedRequest = {
      ...requests.find(request => request.id === id),
      estado: 'rechazado'
    };

    const requestRef = doc(db, "RequestPaint", id);
    await updateDoc(requestRef, updatedRequest);
  };

  const mapEstado = (estado) => {
    switch (estado) {
      case 'aprobado':
        return 'EN PROCESO';
      case 'completado':
        return 'FABRICADO';
      case 'rechazado':
        return 'NO FABRICADO';
      default:
        return estado;
    }
  };


  const filteredHistory = filterPerson
    ? history.filter(item => item.personaAsignada === filterPerson)
    : history;



  if (loading) {
    return <div className="loading">Cargando datos...</div>;
  }

  return (
    <div className="administrativo-container">
      <h2 className='admin-title'>Centro De Control Niples</h2>

      {requests.length === 0 ? (
        <p>No hay solicitudes pendientes registradas.</p>
      ) : (
        <div className="admin-table-container">
          {requests.map((request) => (
            <div key={request.id} className="order-card">
              <div className="order-header">
                <p><strong>Número de Ticket:</strong> P-{request.numeroTicket}</p>
                <button className="detail-btn" onClick={() => toggleRequestDetails(request.id)}>
                  {activeRequestId === request.id ? 'Ocultar Detalles' : 'Ver Detalles'}
                </button>
              </div>

              {activeRequestId === request.id && (
                <div className="details-section">
                  <p><strong>Solicitante:</strong> {request.solicitante.toUpperCase()}</p>
                  <p><strong>Email:</strong> {request.email}</p>
                  <p><strong>Cliente:</strong> {request.cliente.toUpperCase()}</p>
                  <p><strong>Numero Documento:</strong> {request.pedido.toUpperCase()}</p>
                  <p><strong>Fecha Estimada:</strong> {request.fecha.toUpperCase()}</p>

                  <h3>Detalles De La Tuberia</h3>
                  <ul>
                    {request.detalles.map((detalle, itemIndex) => (
                      <li key={itemIndex} className="niple-detail">
                        <strong>Tuberia Solicitada {itemIndex + 1}:</strong> {detalle.descripcion} <br />
                        <strong>Cantidad (MTS):</strong> {detalle.cantidad} <br />
                        <strong>Codigo Ingreso:</strong> {detalle.codigoIngreso} <br />
                        <strong>Color Requerido:</strong> {detalle.colorRequerido} <br />
                        <strong>Tipo De Proceso:</strong> {detalle.tipoProceso} <br />
                        <strong>Sucursal Solicita:</strong> {request.sucursal} <br />
                      </li>
                    ))}
                  </ul>
                  <select value={selectedPerson} onChange={(e) => setSelectedPerson(e.target.value)} className="person-select">
                    <option value="">Seleccione la persona asignada</option>
                    <option value="BLADIMIR DE LA HOZ">BLADIMIR DE LA HOZ</option>
                    <option value="AUDIBETH">AUDIBETH</option>
                    <option value="OTROS">OTROS</option>
                  </select>
                  {/* Campo para la fecha programada */}
                  <div className="scheduled-date-section">
                    <label htmlFor="scheduledDate">Fecha Programada:</label>
                    <input
                      type="date"
                      id="scheduledDate"
                      value={scheduledDate}
                      onChange={(e) => setScheduledDate(e.target.value)}
                      className="date-input"
                      min={new Date().toISOString().split("T")[0]} // Fecha mínima: hoy
                    />
                  </div>

                  <button
                    className="approve-btn"
                    onClick={() => handleApproval(request.id)}
                    disabled={!selectedPerson} // Deshabilita el botón si no hay persona seleccionada
                  >
                    APROBAR
                  </button>
                  <button
                    className="reject-btn"
                    onClick={() => handleRejection(request.id)}
                  >
                    RECHAZAR
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <h2>Historial de Solicitudes</h2>

      <div className="filter-section">
        <label htmlFor="filterPerson">Filtrar por persona asignada:</label>
        <select
          id="filterPerson"
          value={filterPerson}
          onChange={(e) => setFilterPerson(e.target.value)}
          className="filter-select"
        >
          <option value="">Todos</option>
          <option value="BLADIMIR DE LA HOZ">BLADIMIR DE LA HOZ</option>
          <option value="AUDIBETH">AUDIBETH</option>
          <option value="OTROS">OTROS</option>
        </select>
      </div>

      <div className="history-container">
        {filteredHistory.length === 0 ? (
          <p>No hay historial de solicitudes.</p>
        ) : (
          <div className="history-cards">
            {filteredHistory.map((item) => (
              <div
                key={item.id}
                className={`history-card ${item.estado === 'aprobado' ? 'approved' : item.estado === 'rechazado' ? 'rejected' : item.estado === 'completado' ? 'completed' : ''}`}
              >
                <div className="history-card-header">
                  <strong>Número de Ticket:</strong> P-{item.numeroTicket}
                  <button className="info-btn" onClick={() => toggleHistoryDetails(item.id)}>
                    {activeHistoryId === item.id ? <IoMdInformationCircleOutline />
                      : <IoMdInformationCircle />
                    }
                  </button>
                </div>
                {activeHistoryId === item.id && (
                  <div className="history-details">
                    <p><strong>Solicitante:</strong> {item.solicitante.toUpperCase()}</p>
                    <p><strong>Cliente:</strong> {item.cliente.toUpperCase()}</p>
                    <p><strong>Persona Asignada:</strong> {item.personaAsignada || 'No asignada'}</p>
                    <p><strong>Estado:</strong> {mapEstado(item.estado).toUpperCase()}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
