import React, { useState, useEffect } from 'react';
import '../../css/Formp.css';
import { GrSend } from "react-icons/gr";
import { TiDelete } from "react-icons/ti";
import { RiStickyNoteAddFill } from "react-icons/ri";
import { db } from '../FireBase';
import { collection, addDoc, doc, getDoc,setDoc,runTransaction } from 'firebase/firestore';
import { auth } from '../FireBase';


export default function FormularioTuberia() {
  const [tuberiaCount, setTuberiaCount] = useState(0);
  const [tuberiaData, setTuberiaData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputDisabled, setInputDisabled] = useState(false);
  const [, setTicketNumber] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [generatedTicket, setGeneratedTicket] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [istextchange, settextchange] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [days, setDays] = useState(0); // Added state to track the difference in days
  const [userEmail, setUserEmail] = useState('');


  useEffect(() => {
    const fetchTicketNumber = async () => {
      const ticketRef = doc(db, 'ticketCounterP', 'contador');
      const ticketDoc = await getDoc(ticketRef);
      if (ticketDoc.exists()) {
        const data = ticketDoc.data();
        setTicketNumber(data.numero || 0);
      } else {
        console.log("No existe el documento del contador, creando uno nuevo.");
        await setDoc(ticketRef, { numero: 0 });
        setTicketNumber(0);
      }

      const user = auth.currentUser; // Obteniendo el usuario actual
      if (user) {
        setUserEmail(user.email); // Estableciendo el correo electrónico del usuario en el estado
      }
      
    };
    fetchTicketNumber();
  }, []);



  const handleAdd = () => {
    const count = parseInt(inputValue, 10);
    if (count > 0) {
      setTuberiaCount(count);
      setTuberiaData(
        Array(count).fill({
          tipoMaterial: '',
          diametro: '',
          longitud: '',
          sch: '',
          entrada: '',
          salida: '',
          cantidadTotal: '',
          precioVentaTotal: '',
          codigoIngreso: '',
          codigoSalida: '',
          tipoProceso: '',
          colorRequerido: '',
          fechaEntrega: '',
          dias:0
        })
      );
      setInputDisabled(true);
    }
  };

  const dateday = (fecha) => {
    if (fecha) {
      const fechaActual = new Date(); // Current date
      const fechaEstimada = new Date(fecha); // Selected date
      const timeDiff = fechaEstimada - fechaActual; // Time difference in milliseconds
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert to days
      setDays(days); // Update state
      return days; // Return the calculated days
    }
    return 0;
  };



  const handleRemove = () => {
    setTuberiaCount(0);
    setTuberiaData([]);
    setInputDisabled(false);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTuberiaData = [...tuberiaData];
    
    if (name === "fechaEntrega") {
      const daysDifference = dateday(value); // Calculate days difference
      updatedTuberiaData[index] = { ...updatedTuberiaData[index], [name]: value, dias: daysDifference }; // Update the days field
    } else {
      updatedTuberiaData[index] = { ...updatedTuberiaData[index], [name]: value };
    }
    
    setTuberiaData(updatedTuberiaData);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handletextchange = (e) => {
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(e.target.value)) {
      settextchange(e.target.value.toUpperCase());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (selectedValue === '') {
      alert('Por favor seleccione una opcion.');
      return;
    }
    

    const solicitante = e.target[0].value;
    const email = userEmail;
    const cliente = e.target[2].value;
    const pedido = e.target[3].value;
    const sucursal = e.target[4].value;
    const fecha = e.target[20].value;

    const formData = {
      sucursal: sucursal,
      cliente: cliente,
      estado: "solicitado",
      pedido: pedido,
      solicitante: solicitante,
      email: email,
      fecha: fecha,
      detalles: tuberiaData.map(tuberia => ({
        cantidad: Number(tuberia.cantidadTotal) || 0,
        codigoIngreso: tuberia.codigoIngreso || '',
        codigoSalida: tuberia.codigoSalida || '',
        precioVentaTotal: Number(tuberia.precioVentaTotal) || 0,
        tipoProceso: tuberia.tipoProceso || '',
        colorRequerido: tuberia.colorRequerido || '',
        ranuraentrada: tuberia.entrada || '',
        ranurasalida: tuberia.salida || '',
        descripcion: `TUBERIA ${tuberia.tipoMaterial} DE ${tuberia.diametro}" SCH ${tuberia.sch}  ${tuberia.longitud} S/COST` || '',
        dias: days,
      })),
    };

    const ticketRef = doc(db, 'ticketCounterP', 'contador');

    try {
      // Transacción para actualizar el número de ticket
      const newTicketNumber = await runTransaction(db, async (transaction) => {
        const ticketDoc = await transaction.get(ticketRef);
        if (!ticketDoc.exists()) {
          throw new Error("Documento del contador no encontrado.");
        }
  
        const currentTicketNumber = ticketDoc.data().numero;
        const updatedTicketNumber = currentTicketNumber + 1;
  
        // Actualizar el número del contador
        transaction.update(ticketRef, { numero: updatedTicketNumber });
  
        return updatedTicketNumber;
      });
  
      // Agregar el documento con el número de ticket generado
      const docRef = await addDoc(collection(db, "RequestPaint"), {
        ...formData,
        numeroTicket: newTicketNumber,
      });
  
      console.log("Documento escrito con ID: ", docRef.id);
      setGeneratedTicket(newTicketNumber);
  
      // Mostrar modal y guardar número de ticket generado
      setModalOpen(true);
  
      // Limpiar el formulario
      setInputValue('');
      setTuberiaCount(0);
      setTuberiaData([]);
      setInputDisabled(false);
    } catch (e) {
      console.error("Error añadiendo el documento: ", e);
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <div className="formulario-tuberia-container">
      <h2 className="title2">Solicitud De Elaboracion De Horno De Pintura</h2>
      <div className="card">
        <form onSubmit={handleSubmit}>
          {/* Sección de Información del Solicitante */}
          <div className="form-section">
            <h2>Información Del Solicitante</h2>
            <div className="form-grid">
              <div className="form-group">
                <label>Nombre Solicitante:</label>
                <input type="text" style={{ textTransform: 'uppercase' }}
                  value={istextchange}
                  onChange={handletextchange} />
              </div>
              <div className="form-group">
                <label>Correo Electrónico:</label>
                <input type="email" value={userEmail} disabled />
              </div>
              <div className="form-group">
                <label>Cliente:</label>
                <input type="text" style={{ textTransform: 'uppercase' }} />
              </div>
              <div className="form-group">
                <label>Número Documento:</label>
                <input type="text" style={{ textTransform: 'uppercase' }} />
              </div>
              <div className="form-group">
                <label>Sucursal Que Solicita:</label>
                <select>
                  <option>Seleccione</option>
                  <option value="01">01-CARTAGENA</option>
                  <option value="10">10-BARRANQUILLA</option>
                  <option value="20">20-FUNZA</option>
                  <option value="22">22-ECOPETROL</option>
                  <option value="30">30-MEDELLIN</option>
                  <option value="40">40-YUMBO</option>
                  <option value="50">50-MANIZALES</option>
                  <option value="60">60-DOSQUEBRADA</option>
                  <option value="70">70-GIRÓN</option>
                  <option value="90">90-SANTA MARTA</option>
                  <option value="300">300-PALOQUEMAO</option>
                </select>
              </div>
            </div>
          </div>

          {/* Sección para agregar número de tuberías */}
          <div className="form-section">
            <h2>Agregar Número de Tuberías Pintadas</h2>
            <div className="list-header">
              <label>Número de Tuberías:</label>
              <input
                type="number"
                value={inputValue}
                onChange={handleInputChange}
                disabled={inputDisabled}
                className="custom-input"
              />
              <button
                type="button"
                onClick={handleAdd}
                disabled={inputDisabled}
                className="button button-blue"
              >
                <RiStickyNoteAddFill className="icon-send1" />
              </button>
              <button
                type="button"
                onClick={handleRemove}
                className="button button-red"
              >
                <TiDelete className="icon-send1" />
              </button>
            </div>
          </div>

          {tuberiaCount > 0 && (
            <div className="form-section">
              <h2>Servicio De Pintura</h2>
              <div className="niple-forms">
                {tuberiaData.map((tuberia, index) => (
                  <div key={index} className="niple-form">
                    <h3>Orden De Horno De Pintura {index + 1}</h3>
                    <div className="niple-form-grid">
                      <div className="niple-form-group">
                        <label>Tipo de Material:</label>
                        <select type="text" name="tipoMaterial" value={tuberia.tipoMaterial} onChange={(e) => handleChange(index, e)}
                        >
                          <option value="">Seleccione</option>
                          <option value="A/C">A/C</option>
                          <option value="A/I">A/I</option>
                          <option value="GALV.">GALV.</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Diámetro:</label>
                        <select
                          name="diametro"
                          value={tuberia.diametro}
                          onChange={(e) => {
                            setSelectedValue(e.target.value);
                            handleChange(index, e);
                          }}
                        >
                          <option value="">-</option>
                          <option value="1/2'">1/2"</option>
                          <option value="3/4'">3/4"</option>
                          <option value="1'">1"</option>
                          <option value="1 1/4'">1 1/4"</option>
                          <option value="1 1/2'">1 1/2"</option>
                          <option value="2'">2"</option>
                          <option value="2 1/2'">2 1/2"</option>
                          <option value="3'">3"</option>
                          <option value="4'">4"</option>
                          <option value="5'">5"</option>
                          <option value="6'">6"</option>
                          <option value="8'">8"</option>
                          <option value="10'">10"</option>
                          <option value="12'">12"</option>
                          <option value="14'">14"</option>
                          <option value="16'">16"</option>
                          <option value="18'">18"</option>
                          <option value="20'">20"</option>
                          <option value="22'">22"</option>
                          <option value="24'">24"</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Longitud:</label>
                        <select
                          name="longitud"
                          value={tuberia.longitud}
                          onChange={(e) => handleChange(index, e)}
                        >
                          <option value="">Seleccione</option>
                          <option value="DRL">DRL</option>
                          <option value="RL">RL</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>SCH:</label>
                        <select
                          name="sch"
                          value={tuberia.sch}
                          onChange={(e) => handleChange(index, e)}
                        >
                          <option value="">Seleccione</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="40">40</option>
                          <option value="80">80</option>
                          <option value="120">120</option>
                          <option value="140">140</option>
                          <option value="160">160</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Ranura de Entrada:</label>
                        <select
                          name="entrada"
                          value={tuberia.entrada}
                          onChange={(e) => handleChange(index, e)}
                        >
                          <option value="">Seleccione</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Ranura de Salida:</label>
                        <select
                          name="salida"
                          value={tuberia.salida}
                          onChange={(e) => handleChange(index, e)}
                        >
                          <option value="">Seleccione</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Cantidad Total (MTS):</label>
                        <input
                          type="number"
                          name="cantidadTotal"
                          value={tuberia.cantidadTotal}
                          onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => {
                            if (e.key === ',' || e.key === '-' || e.key === 'e' || e.key === '.') {
                              e.preventDefault(); // Evita que se introduzcan estos caracteres
                            }
                          }
                          }
                        />
                      </div>
                      <div className="niple-form-group">
                        <label>Precio de Venta Total:</label>
                        <input
                          type="number"
                          name="precioVentaTotal"
                          value={tuberia.precioVentaTotal}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <div className="niple-form-group">
                        <label>Código De Ingreso:</label>
                        <input
                          type="text"
                          style={{ textTransform: 'uppercase' }}
                          name="codigoIngreso"
                          value={tuberia.codigoIngreso}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <div className="niple-form-group">
                        <label>Código De Salida:</label>
                        <input
                          type="text"
                          style={{ textTransform: 'uppercase' }}
                          name="codigoSalida"
                          value={tuberia.codigoSalida}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                      <div className="niple-form-group">
                        <label>Tipo de Proceso:</label>
                        <select
                          name="tipoProceso"
                          value={tuberia.tipoProceso}
                          onChange={(e) => handleChange(index, e)}
                        >
                          <option value="">Seleccione</option>
                          <option value="HORNO">HORNO</option>
                          <option value="SOPLETE">SOPLETE</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Color Requerido:</label>
                        <select
                          name="colorRequerido"
                          value={tuberia.colorRequerido}
                          onChange={(e) => handleChange(index, e)}
                        >
                          <option value="">Seleccione</option>
                          <option value="NEGRO">NEGRO</option>
                          <option value="ROJO">ROJO</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                      <label>Fecha De Entrega:</label>
                      <input type="date" 
                      name="fechaEntrega" 
                      value={tuberia.fechaEntrega} 
                      onChange={(e) => handleChange(index, e)}
                      min={new Date().toISOString().split("T")[0]}
                      />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button type="submit" className="button button-submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <span className="loader"></span> // Mostrar un loader mientras se envía
                ) : (
                  <>
                    <GrSend className='icon-send' /> Enviar
                  </>
                )}
              </button>
            </div>
          )}
        </form>
      </div>

      {/* Modal para mostrar el ticket generado */}
      {modalOpen && (
        <div className="modal-ticket">
          <div className="modal-content-ticket">
            <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
            <h2>Ticket Generado</h2>
            <p>El número de ticket generado es: <strong>P-{generatedTicket}</strong></p>
            <button onClick={() => setModalOpen(false)} className='Cerrar-ticket'>Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
}
