import React, { useState, useEffect } from 'react';
import { FiDownload, } from "react-icons/fi";
import { MdNavigateNext, MdArrowBackIos } from "react-icons/md";
import '../../css/Board.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { db } from '../FireBase';
import { collection, onSnapshot } from 'firebase/firestore';
import logo from '../../assets/logo_tuvacol1.png';
import firmaRevisa from '../../assets/firma_shirly.png'; // Reemplaza con la ruta correcta
import firmaRecibe from '../../assets/firma_mario.png'; // Reemplaza con la ruta correcta


export default function Panel() {
  const [orders, setOrders] = useState([]);
  const [activeOrderIndex, setActiveOrderIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // Estado para la página actual
  const ordersPerPage = 5; // Órdenes por página

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "RequestNiples"), (snapshot) => {
      const ordersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const sortedOrders = ordersData.sort((a, b) => {
        // Asignar prioridad a los estados
        const priority = { SOLICITADO: 1, APROBADO: 2, COMPLETADO: 3, RECHAZADO: 4 };

        // Comparar por estado
        const stateComparison = priority[a.estado.toUpperCase()] - priority[b.estado.toUpperCase()];
        if (stateComparison !== 0) {
          return stateComparison;  // Si los estados son diferentes, se ordena por estado
        }

        // Si los estados son iguales, ordenar por número de ticket
        const TicketA = a.numeroTicket || 0;
        const TicketB = b.numeroTicket || 0;

        return TicketA - TicketB;
      });

      setOrders(sortedOrders);
    });

    return () => unsubscribe();
  }, []);


  const generatePDF = (order) => {
    if (!order) {
      console.error("Error: Los detalles de la orden no están disponibles.");
      return;
    }

    const doc = new jsPDF();
    const margin = 10;

    // Añadir el logo
    doc.addImage(logo, 'PNG', margin, margin, 40, 20);

    // Título centrado con letras "pegadas"
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold'); // Estilo de fuente en negrita
    doc.setTextColor(0, 51, 153); // Color azul oscuro

    const pageWidth = doc.internal.pageSize.getWidth();

    // Primera línea del título
    let text = "ORDEN PARA ELABORACIÓN";
    let textWidth = doc.getTextWidth(text);
    doc.text(text, (pageWidth - textWidth) / 2, 28); // Centrada en y = 28

    // Segunda línea del título, pegada a la primera
    text = "DE TRABAJOS EN TALLER";
    textWidth = doc.getTextWidth(text);
    doc.text(text, (pageWidth - textWidth) / 2, 34); // Centrada en y = 34

    // Añadir número de ticket
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Número de Ticket: ${order.numeroTicket}`, margin, 40);

    // Subtítulo y versión del documento (alineado a la derecha)
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Negro para el texto
    doc.text("Versión: 05", pageWidth - margin - 40, 15);
    doc.text("Vigente desde: 24-abr-2019", pageWidth - margin - 40, 20);

    // Información de la Orden en una tabla
    doc.autoTable({
      startY: 45,
      head: [['Solicitante', 'Fecha Actual', 'Cliente', 'Nombre de Operario', 'Pedido']],
      body: [[
        order.solicitante.toUpperCase() || 'N/A',
        order.fecha || 'N/A',
        order.cliente.toUpperCase() || 'N/A',
        order.personaAsignada || 'N/A',
        order.pedido.toUpperCase() || 'N/A',
      ]],
      theme: 'grid',
      headStyles: { fillColor: [0, 51, 153], textColor: [255, 255, 255] },
      bodyStyles: { fontSize: 10 },
      styles: { cellPadding: 1, halign: 'center' }, // Alineación centrada
    });

    // Encabezado para Material Entrada
    const entradaY = doc.autoTable.previous.finalY + 10; // Y inicial después de la tabla anterior
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 51, 153);
    let entradaText = "ENTRADA DE MATERIAL";
    let entradaTextWidth = doc.getTextWidth(entradaText);
    doc.text(entradaText, (pageWidth - entradaTextWidth) / 2, entradaY); // Centrando el texto

    // Tabla para los detalles del Niple
    doc.autoTable({
      startY: entradaY + 7,
      head: [['Item', 'Código de Niple', 'Cantidad (UND)', 'Descripción']],
      body: order.detalles.map((item, index) => [
        index + 1,
        item.codigoNiple || 'N/A',
        item.cantidad || 'N/A',
        item.descripcion || 'N/A',
      ]),
      theme: 'grid',
      headStyles: { fillColor: [0, 51, 153], textColor: [255, 255, 255] },
      bodyStyles: { fontSize: 10 },
      styles: { cellPadding: 1 },
    });

    // Encabezado para Material Salida
    const salidaY = doc.autoTable.previous.finalY + 10; // Y inicial después de la tabla anterior
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 51, 153);
    let salidaText = "SALIDA DE MATERIAL";
    let salidaTextWidth = doc.getTextWidth(salidaText);
    doc.text(salidaText, (pageWidth - salidaTextWidth) / 2, salidaY); // Centrando el texto

    // Tabla para los detalles de la Tubería
    doc.autoTable({
      startY: salidaY + 7,
      head: [['Item', 'Código de Tubería', 'Bodega', 'Cantidad (MTS)', 'Sobrante (MTS)']],
      body: order.detalles.map((item, index) => [
        index + 1,
        item.codigoTuberia || 'N/A',
        item.bodega || 'N/A',
        item.cantidadtuberia || 'N/A',
        item.sobrante || 'N/A',
      ]),
      theme: 'grid',
      headStyles: { fillColor: [0, 51, 153], textColor: [255, 255, 255] },
      bodyStyles: { fontSize: 10 },
      styles: { cellPadding: 1 },
    });

    // Espacio para observaciones
    const finalY = doc.autoTable.previous.finalY;
    doc.setFontSize(10);
    doc.text("Observaciones:", margin, finalY + 14);
    doc.setLineWidth(0.5);
    doc.line(margin, finalY + 21, pageWidth - margin, finalY + 21);

    // Agregar imágenes de las firmas
    doc.addImage(firmaRevisa, 'PNG', margin, finalY + 35, 35, 15);  // Firma "Revisa"
    doc.text("Revisa:", margin, finalY + 30); // Etiqueta de firma "Revisa" arriba de la imagen

    doc.addImage(firmaRecibe, 'PNG', pageWidth / 2, finalY + 35, 35, 15);  // Firma "Recibe"
    doc.text("Recibe:", pageWidth / 2, finalY + 30); // Etiqueta de firma "Recibe" arriba de la imagen

    // Guardar el PDF
    doc.save(`detalles_orden_N-${order.numeroTicket}.pdf`);
  };




  const toggleDetails = (index) => {
    setActiveOrderIndex(activeOrderIndex === index ? null : index);
  };



  const getOrderBackgroundColor = (status) => {
    const upperStatus = status.toUpperCase()
    if (upperStatus === 'COMPLETADO') {
      return '#C0EBA6';
    } else if (upperStatus === 'RECHAZADO') {
      return '#FF8A8A'
    } else if (upperStatus === 'APROBADO') {
      return '#FFF4B5'
    } else if (upperStatus === 'SOLICITADO') {
      return '#C7C8CC'

    }
  };


  // Navegación entre páginas
  const handleNextPage = () => {
    if ((currentPage + 1) * ordersPerPage < orders.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const mapEstado = (estado) => {
    switch (estado) {
      case 'aprobado':
        return 'EN PROCESO';
      case 'completado':
        return 'FABRICADO';
      case 'rechazado':
        return 'NO FABRICADO';
      case 'solicitado':
        return 'EN ESPERA';
      default:
        return estado;
    }
  };

  return (
    <div className="panel-container">
      <h2>Estado Actual de Órdenes</h2>
      {orders.slice(currentPage * ordersPerPage, (currentPage + 1) * ordersPerPage).map((order, orderIndex) => (
        <div key={order.id} className="order-card" style={{ backgroundColor: getOrderBackgroundColor(order.estado) }}>
          <div className="order-header">
            <p><strong>Fecha:</strong> {order.fechaProgramada}</p>
            <p><strong>Ticket:</strong> N-{order.numeroTicket}</p>
            <p><strong>Pedido:</strong> {order.pedido.toUpperCase()}</p>
            <p><strong>Cliente:</strong> {order.cliente.toUpperCase()}</p>
            <p><strong>Estado:</strong> {mapEstado(order.estado).toUpperCase()}</p>
            <button className="detail-btn" onClick={() => toggleDetails(orderIndex)}>
              {activeOrderIndex === orderIndex ? 'Ocultar Detalles' : 'Ver Detalles'}
            </button>
          </div>

          {activeOrderIndex === orderIndex && (
            <div className="details-section">
              {order.detalles.map((item, itemIndex) => (
                <div key={itemIndex} className="niple-detail">
                  <div className="niple-info">
                    <p>
                      <strong>{item.descripcion}</strong>
                    </p>
                    <p>
                      Bodega: <strong>{item.bodega}</strong> &nbsp;
                      Cantidad: <strong>{item.cantidad}</strong> &nbsp;
                      Código De Tubería: <strong>{item.codigoTuberia}</strong> &nbsp;
                      Código De Niple: <strong>{item.codigoNiple}</strong> &nbsp;
                    </p>
                  </div>
                </div>
              ))}
              
              {/* Mostrar observacion3 solo si existe */}
              {order.observacion3 && order.observacion3.trim() !== '' && (
                <div className="observation-section">
                  <p><strong>NOTA DEL ADMINISTRADOR:</strong> {order.observacion3.toUpperCase()}</p>
                </div>
              )}

              {['APROBADO', 'COMPLETADO'].includes(order.estado.toUpperCase()) && order.personaAsignada && (
                <div className="assigned-person">
                  <p>
                    <strong>Persona Asignada:</strong> {order.personaAsignada}
                  </p>
                </div>
              )}

              <div className="status-and-pdf">
                <button
                  onClick={() => generatePDF(order)}
                  className="pdf-btn"
                  disabled={order.estado.toUpperCase() !== 'COMPLETADO'} // Deshabilita si no está completado
                  style={{
                    opacity: order.estado.toUpperCase() !== 'COMPLETADO' ? 0.5 : 1, // Cambia la opacidad
                    cursor: order.estado.toUpperCase() !== 'COMPLETADO' ? 'not-allowed' : 'pointer' // Cambia el cursor
                  }}
                >
                  <FiDownload /> Descargar PDF
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
      {/* Controles de navegación */}
      <div className="pagination-controls">
        <button className="nav-btn" onClick={handlePreviousPage} disabled={currentPage === 0}>
          <MdArrowBackIos />
        </button>
        <button className="nav-btn" onClick={handleNextPage} disabled={(currentPage + 1) * ordersPerPage >= orders.length}>
          <MdNavigateNext />
        </button>
      </div>
    </div>
  );
}